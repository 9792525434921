<template>
  <v-container>
    <!-- notifications -->
    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <!-- loading screen -->
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="8">
        <!-- header text -->
        <v-row>
          <v-col>
            <h1>Objective List</h1>
            <p class="grey--text">
              Below are the list of objectives for the stage
              <b>
                {{ stg_itm.kbn_stg.stage_name | capitalize }}
              </b>
              grouped by their status. You can sort on any columns you like and
              click on the row to view the objective.
            </p>
          </v-col>
        </v-row>

        <ObjectiveList
          :stg_itm="stg_itm"
          :kanban_type="kanban_type"
          :stage_item_id="stage_item_id"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import ObjectiveList from "@/components/kanban/Objective_List.vue";
import filt from "@/plugins/filters";

export default {
  props: ["stage_item_id", "kanban_type"],

  components: {
    ObjectiveList,
  },

  data: () => ({
    loading: true,
    error: false,
    errorMessage: "",
    stg_itm: null,
  }),

  methods: {
    async readStageItem() {
      try {
        this.loading = true;

        const res = await API().get(
          `api/kanban/read_stage_item/?stg_itm_id=${this.stage_item_id}`
        );

        // console.log("stage item: ", res);

        if (res.status === 200) {
          this.stg_itm = res.data;

          this.loading = false;
        } else {
          this.error = true;
          this.errorMessage = "Failed to fetch stage item";
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.loading = false;
      }
    },
  },

  mounted() {
    this.readStageItem();
  },

  filters: {
    ...filt,
  },
};
</script>

<style lang="scss">
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
